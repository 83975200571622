















import Component from 'vue-class-component';
import Vue from 'vue';

@Component({
  name: 'rs-onboarding-header'
})
export default class DashboardHeader extends Vue {
}
