





















import Component from 'vue-class-component';
import Vue from 'vue';
import RsOnboardingHeader from '@/modules/onboarding/components/main/header.vue';

@Component({
  components: {RsOnboardingHeader},
})
export default class OnboardingAuth extends Vue {}
